import { useCallback } from 'react';
import { useToastSaurus } from 'services/app/hooks';
import { useGetPerfis } from 'data/api/gestao/perfil/get-perfis';
import { PessoaModel } from 'model/api/gestao/pessoa';
import { ProdutoResumidoModel } from 'model/api/gestao/produto/produto/produto-resumido-model';
import { ProdutoCategoriaModel } from 'model/api/gestao/produto/produto-categoria/produto-categoria-model';
import { TouchoneDBPrimary } from 'database/touchone-database';
import { TabelaCategorias } from 'database/interfaces/interface-tabela-categorias';
import { picker } from 'utils/picker';
import { GestaoStorageKeys, useGestaoStorage } from './gestao-storage';
import { useHistory } from 'react-router-dom';
import { useContratoAtual } from './contrato-atual';
import { EnumContratoConfig } from 'model/enums/enum-contrato-config';
import { useGetPadroesContrato } from 'data/api/gestao/padroes-contrato/padroes-contrato';
import { useSessaoAtual } from '../providers/sessao-atual';
import { useGetPessoaById } from 'data/api/gestao/pessoa/get-pessoa-by-id';
import { PerfilModel } from 'model/api/gestao/master/perfil';
import { isEmpty } from 'lodash';

export const useCadastroPadrao = () => {
  const { getRegistro, setRegistro, delRegistro, exists } = useGestaoStorage();
  const { getConfigByCod, refreshContrato } = useContratoAtual()
  const { getPessoa } = useSessaoAtual()
  const { getPessoaById, carregando: carregandoPessoaById } = useGetPessoaById()
  const { getPadroesContrato, carregando: carregandoPadroesContrato } = useGetPadroesContrato();
  const { getPerfisDrop, carregando: carregandoPerfis } = useGetPerfis();
  const { showToast } = useToastSaurus();
  const history = useHistory();

  const getPerfisWrapper = useCallback(async () => {
    try {
      const ret = await getPerfisDrop();
      if (ret.erro) throw ret.erro;
      let perfis: Array<PerfilModel> = new Array<PerfilModel>();
      if (ret.resultado?.data?.list?.length > 0) {
        perfis = ret.resultado?.data?.list as Array<PerfilModel>;
      }
      return perfis;
    } catch (e: any) {
      return [];
    }
  }, [getPerfisDrop]);

  const getPerfis = useCallback(async () => {
    let perfis = new Array<PerfilModel>();
    try {
      if (!exists(GestaoStorageKeys.CadastroPerfis, false)) {
        perfis = await getPerfisWrapper();
        setRegistro(GestaoStorageKeys.CadastroPerfis, perfis, false);
      } else {
        perfis = getRegistro(
          GestaoStorageKeys.CadastroPerfis, false
        ) as Array<PerfilModel>;
      }
    } catch (e: any) {
      showToast(
        'error',
        `Ocorreu um erro ao carregar os Perfis. Detalhe: ${e.message}`,
      );
    } finally {
      return perfis;
    }
  }, [exists, getPerfisWrapper, getRegistro, setRegistro, showToast]);

  const refetchPerfis = useCallback(async () => {
    delRegistro(GestaoStorageKeys.CadastroPerfis, false);
  }, [delRegistro]);

  const persistConsumidor = useCallback(
    (consumidor: PessoaModel) => {
      if (consumidor) {
        setRegistro(GestaoStorageKeys.ConsumidorPadrao, consumidor, false);
      } else {
        delRegistro(GestaoStorageKeys.ConsumidorPadrao, false);
      }
    },
    [delRegistro, setRegistro],
  );

  const getConsumidor = useCallback(async (refetch: boolean = true): Promise<PessoaModel | undefined> => {
    try {
      const consumidorPadrao = getRegistro(
        GestaoStorageKeys.ConsumidorPadrao, false
      ) as PessoaModel;

      if (Object.entries(consumidorPadrao).length === 0) {
        const contratoId = getPessoa().pessoa.contratoId || '';
        const ret = await getPadroesContrato(contratoId, '326');
        if (ret.erro) throw ret.erro;

        if (!ret.resultado?.data.valor && refetch) {
          await refreshContrato();
          const consumidor = await getConsumidor(false);
          return consumidor;
        }

        if (!ret.resultado?.data.valor && !refetch) {
          showToast('error', 'Não foi possível identificar o cliente padrão');
          history.push('/');
          return
        }

        const resCP = await getPessoaById(ret.resultado?.data.valor);
        if (resCP.erro) throw resCP.erro;

        let cP = new PessoaModel();
        if (ret.resultado?.data && resCP.resultado?.data) {
          cP = resCP.resultado?.data;
          persistConsumidor(cP);
          return cP;
        }
      }
      return consumidorPadrao;
    } catch (e: any) {
      return;
    }
  }, [getPadroesContrato, getPessoa, getPessoaById, getRegistro, history, persistConsumidor, refreshContrato, showToast]);

  const refreshConsumidor = useCallback(async () => {
    delRegistro(GestaoStorageKeys.ConsumidorPadrao);
    getConsumidor();
  }, [delRegistro, getConsumidor]);


  const getProdutoAvulso = useCallback(async (refetch: boolean = true): Promise<ProdutoResumidoModel | undefined> => {
    try {
      const ret = getConfigByCod(EnumContratoConfig.ProdutoGenerico);

      if (!ret && refetch) {
        await refreshContrato();
        const prod = await getProdutoAvulso(false);
        return prod;
      }

      if (!ret && !refetch) {
        showToast('error', 'Não foi possível identificar o produto avulso');
        history.push('/');
        return
      }

      const produtoAvulsoRes = await TouchoneDBPrimary.produtos.get({ produtoId: ret })
      if (isEmpty(produtoAvulsoRes)) {
        return undefined
      }
      let prodAvulso = new ProdutoResumidoModel();
      if (ret && produtoAvulsoRes) {
        prodAvulso = picker<ProdutoResumidoModel>(produtoAvulsoRes, prodAvulso);
      }

      prodAvulso.codigos = [...(produtoAvulsoRes?.codigos ?? [])]

      return prodAvulso;
    } catch (e: any) {
      return undefined;
    }
  }, [getConfigByCod, history, refreshContrato, showToast]);

  const getProdutoServico = useCallback(async () => {
    try {

      const ret = getConfigByCod(EnumContratoConfig.ProdutoServico);

      if (!ret) {
        return undefined;
      }

      const produtoServicoRes = await TouchoneDBPrimary.produtos.get({ produtoId: ret })
      if (!produtoServicoRes) {
        return undefined
      }
      let prodAvulso = new ProdutoResumidoModel();
      prodAvulso = picker<ProdutoResumidoModel>(produtoServicoRes, prodAvulso);

      prodAvulso.codigos = [...(produtoServicoRes?.codigos ?? [])]

      return prodAvulso;
    } catch (e: any) {
      return undefined;
    }
  }, [getConfigByCod]);

  const getProdutoEntrega = useCallback(async () => {
    try {

      const ret = getConfigByCod(EnumContratoConfig.ProdutoEntrega);

      if (!ret) {
        return undefined;
      }

      const produtoServicoRes = await TouchoneDBPrimary.produtos.get({ produtoId: ret })

      let prodAvulso = new ProdutoResumidoModel();
      if (ret && produtoServicoRes) {
        prodAvulso = picker<ProdutoResumidoModel>(produtoServicoRes, prodAvulso);
      }

      prodAvulso.codigos = [...(produtoServicoRes?.codigos ?? [])]

      return prodAvulso;
    } catch (e: any) {
      return undefined;
    }
  }, [getConfigByCod]);

  const getCategorias = useCallback(
    async () => {
      let categorias = new Array<ProdutoCategoriaModel | TabelaCategorias>();
      try {
        categorias = await TouchoneDBPrimary.categorias.toArray()
      } catch (e: any) {
        showToast(
          'error',
          `Ocorreu um erro ao carregar as categorias dos produtos. Detalhe: ${e.message}`,
        );
      } finally {
        return categorias;
      }
    },
    [showToast],
  );

  return {
    carregando: carregandoPerfis || carregandoPessoaById || carregandoPadroesContrato,
    getProdutoAvulso,
    getCategorias,
    getPerfis,
    refetchPerfis,
    getConsumidor,
    refreshConsumidor,
    getProdutoServico,
    getProdutoEntrega,
  }
};
