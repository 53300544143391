import { useGetObterCupomVenda } from 'data/api/gestao/venda/get-obter-cupom-venda';
import { useGetVendaCompleta } from 'data/api/gestao/venda/get-venda-completa';
import { usePostEnviarCupomVenda } from 'data/api/gestao/venda/post-enviar-cupom-venda';
import { usePostEnviarCupomVendaSMS } from 'data/api/gestao/venda/post-enviar-cupom-venda-sms';
import { EnumMovModelo } from 'model';
import { EnviarSMSModel } from 'model/api/gestao/enviar-cupom/enviar-cupom-sms';
import { VendaCompletaModel } from 'model/api/gestao/venda/venda-completa-model';
import { useCallback } from 'react';
import { ImpressaoSaurus } from './impressao-saurus';
import { useImpressaoMov } from './impressao-mov';
import { useImpressaoNFCe } from './impressao-nfce';
import { useImpressaoTicket } from './impressao-ticket';
import { usePDV } from './pdv';
import { useDevice } from './device';
import { useCadastroPadrao } from './cadastro-padrao';
import { useVenda } from './gerenciar-venda';
import { EnumTpEmis } from 'model/enums/enum-tp-emis';
import { usePostEnviarCupomVendaEmail } from 'data/api/gestao/venda/post-enviar-cupom-venda-email';
import { EnumPDVTpCaixa } from 'model/enums/enum-pdv-tpcaixa';
import { useImpressaoLocal } from './impressao-local';
import { EnumTipoImpressao } from 'model/enums/enum-tipo-impressao';
import { EnumTpProduto } from 'model/enums/enum-tp-produto';
import { useImpressaoSAT } from './impressao-sat';
import { useSessaoAtual } from '../providers/sessao-atual';
import { EnumPDVConfigCod } from 'model/enums/enum-pdv-config';
import { stringNumeros } from 'utils/string-numeros';

interface ImprimirProps {
  impressao: string
  tipoImpressao?: EnumTipoImpressao
  serie?: string
  vendaId?: string
  checkTipoPdv?: boolean
  useQtdeColunasPDV?: boolean
}

export function useMovSimples() {
  const { usuario } = useSessaoAtual();
  const { getObterCupomVenda, carregando: carregandoCupomVenda } =
    useGetObterCupomVenda();
  const { getVendaCompleta, carregando: carregandoVendaCompleta } =
    useGetVendaCompleta();
  const { postEnviarCupomVenda, carregando: postEnviarVendaCarregando } =
    usePostEnviarCupomVenda();

  const {
    postEnviarCupomVendaEmail,
    carregando: postEnviarVendaEmailCarregando
  } = usePostEnviarCupomVendaEmail();
  const { postEnviarCupomVendaSMS, carregando: postEnviarVendaSMSCarregando } =
    usePostEnviarCupomVendaSMS();
  const { getPDV, getImpressoraPdv, getConfigByCod } = usePDV();
  const { getConsumidor } = useCadastroPadrao();
  const { impressaoNFCe } = useImpressaoNFCe();
  const { impressaoMov } = useImpressaoMov();
  const { impressaoSAT } = useImpressaoSAT();
  const { impressaoTicket } = useImpressaoTicket();
  const { getVenda, converterParaModeloDeImpressao } = useVenda();
  const { carregandoImpressaoLocal, enviarImpressaoLocal } =
    useImpressaoLocal();

  const {
    printHtml,
    printNative,
    printType,
    carregando: carregandoDevice
  } = useDevice();

  const carregando =
    carregandoCupomVenda ||
    carregandoDevice ||
    postEnviarVendaSMSCarregando ||
    postEnviarVendaCarregando ||
    postEnviarVendaEmailCarregando ||
    carregandoImpressaoLocal ||
    carregandoVendaCompleta;

  const retornarModeloImpressao = useCallback(
    async (idVenda: string, tipoImpressao: EnumTipoImpressao) => {
      let venda: VendaCompletaModel | undefined = undefined;
      let serie: number = 0;
      const qtdeColunasPDV = getConfigByCod(EnumPDVConfigCod.QuantidadeColunas);
      const impressora = getImpressoraPdv();

      const qtdeColunas = impressora ? impressora.colunas : qtdeColunasPDV

      const vendaOFF = await getVenda(idVenda);
      venda = vendaOFF
        ? await converterParaModeloDeImpressao({
          ...vendaOFF,
          produtos: vendaOFF.produtos.filter(
            (p) => p.tpProduto !== EnumTpProduto.Combo
          )
        })
        : undefined;
      serie = vendaOFF?.nnf ?? 0;

      if (
        !vendaOFF ||
        vendaOFF.mod === EnumMovModelo.NFCE ||
        vendaOFF.mod === EnumMovModelo.NFE
      ) {
        const response = await getVendaCompleta(idVenda);
        if (response.erro) {
          throw new Error(response.erro);
        }
        venda = response.resultado!.data as VendaCompletaModel;
        serie = venda.infMov.nnf;
      }

      if (!venda) {
        throw new Error('Nenhuma venda foi identificada.');
      }

      let textImpressao = '';

      if (tipoImpressao === EnumTipoImpressao.TICKET) {
        textImpressao = await impressaoTicket(
          venda.infMov,
          Number(qtdeColunas ?? 32),
          venda.infMov.tpEmis
        );
      }

      if (tipoImpressao === EnumTipoImpressao.VENDA) {
        const consumidorPadrao = await getConsumidor();
        if (!consumidorPadrao)
          throw new Error('O Consumidor padrão não foi Identificado.');

        if (venda.infMov.mod === EnumMovModelo.NFCE) {
          textImpressao = impressaoNFCe(
            venda,
            Number(qtdeColunas ?? 32),
            3,
            2,
            consumidorPadrao
          );
        } else if (
          venda.infMov.mod === EnumMovModelo.SATPDV ||
          venda.infMov.mod === EnumMovModelo.SAT
        ) {
          textImpressao = impressaoSAT(
            venda,
            Number(qtdeColunas ?? 32),
            3,
            2,
            consumidorPadrao
          );
        } else {
          textImpressao = impressaoMov(
            venda,
            Number(qtdeColunas ?? 32),
            3,
            2,
            consumidorPadrao,
            venda.infMov.mod === EnumMovModelo.NFE
          );
        }
      }
      return { textImpressao, serie };
    },
    [
      converterParaModeloDeImpressao,
      getConfigByCod,
      getConsumidor,
      getVenda,
      getVendaCompleta,
      impressaoMov,
      impressaoNFCe,
      impressaoSAT,
      impressaoTicket,
      getImpressoraPdv
    ]
  );

  const imprimir = useCallback(async ({
    impressao,
    serie,
    tipoImpressao,
    vendaId,
    checkTipoPdv = true,
    useQtdeColunasPDV = true
  }: ImprimirProps) => {

    const returnTipoImpressao = () => {
      const tipos = {
        [EnumTipoImpressao.VENDA]: () => 'Venda',
        [EnumTipoImpressao.TICKET]: () => 'Ticket',
        [EnumTipoImpressao.PAGAMENTO]: () => 'Pagamento',
        [EnumTipoImpressao.PARCELAS]: () => 'Parcelas'
      }

      return tipoImpressao ? tipos[tipoImpressao]() : ''
    }

    const pdvEmUso = getPDV();
    const qtdeColunasPDV = getConfigByCod(EnumPDVConfigCod.QuantidadeColunas);
    const impressora = getImpressoraPdv();
    const qtdeColunas = impressora ? impressora.colunas : qtdeColunasPDV

    if (printType() === 'HTML') {
      if (
        pdvEmUso?.tpCaixa === EnumPDVTpCaixa.WEBPDV &&
        checkTipoPdv &&
        impressora
      ) {
        await enviarImpressaoLocal(
          vendaId ?? '',
          serie ?? '',
          impressao ?? '',
          returnTipoImpressao(),
          useQtdeColunasPDV ? Number(qtdeColunas || '0') : null
        );
        return;
      }

      const imprimir = new ImpressaoSaurus('HTML');
      const cupomTraduzido = imprimir.Traduz(impressao ?? '');

      return await printHtml(
        cupomTraduzido!,
        Number(qtdeColunas ?? 38)
      );
    } else if (printType() === 'NATIVE') {

      const caminho = getConfigByCod(EnumPDVConfigCod.PortaComunicacao);
      const modelo = getConfigByCod(EnumPDVConfigCod.ModeloEquipamento);

      return await printNative(
        impressao,
        Number(qtdeColunasPDV || '0'),
        caminho ?? '',
        modelo ?? '',
        1
      );

    }
  }, [enviarImpressaoLocal, getConfigByCod, getImpressoraPdv, getPDV, printHtml, printNative, printType])

  const imprimirCupom = useCallback(
    async (
      idVenda: string,
      tpEmis: EnumTpEmis,
      mod: number,
      checkTipoPdv: boolean = true
    ): Promise<void> => {
      const modeloDeImpressao = await retornarModeloImpressao(
        idVenda,
        EnumTipoImpressao.VENDA
      );

      await imprimir({
        impressao: modeloDeImpressao.textImpressao,
        checkTipoPdv,
        serie: modeloDeImpressao.serie.toString(),
        tipoImpressao: EnumTipoImpressao.VENDA,
        vendaId: idVenda
      })
    },
    [imprimir, retornarModeloImpressao]
  );

  const imprimirTicket = useCallback(
    async (
      idVenda: string,
      tpEmis: EnumTpEmis,
      checkTipoPdv: boolean = true,
      printType: string = 'HTML'
    ): Promise<void> => {
      const modeloDeImpressao = await retornarModeloImpressao(
        idVenda,
        EnumTipoImpressao.TICKET
      );
      const ticket = new ImpressaoSaurus('HTML');
      const impressora = getImpressoraPdv();

      // PDV E SUAS CONFIGS
      const pdv = getPDV();
      const qtdeColunasPDV = getConfigByCod(EnumPDVConfigCod.QuantidadeColunas);
      const caminho = getConfigByCod(EnumPDVConfigCod.PortaComunicacao);
      const modelo = getConfigByCod(EnumPDVConfigCod.ModeloEquipamento);

      const qtdeColunas = impressora ? impressora.colunas : qtdeColunasPDV

      if (printType === 'HTML') {
        if (
          pdv?.tpCaixa === EnumPDVTpCaixa.WEBPDV &&
          checkTipoPdv &&
          impressora
        ) {
          await enviarImpressaoLocal(
            idVenda,
            modeloDeImpressao.serie.toString(),
            modeloDeImpressao.textImpressao ?? '',
            'Ticket',
            Number(qtdeColunas || '0'),
          );
          return;
        }

        let ret = ticket.Traduz(modeloDeImpressao.textImpressao);
        return await printHtml(ret || '', Number(qtdeColunas || '0'));
      }

      if (printType === 'NATIVE') {
        if (
          pdv?.tpCaixa === EnumPDVTpCaixa.WEBPDV &&
          checkTipoPdv &&
          impressora
        ) {
          await enviarImpressaoLocal(
            idVenda,
            modeloDeImpressao.serie.toString(),
            modeloDeImpressao.textImpressao ?? '',
            'Ticket',
            Number(qtdeColunas || '0')
          );
          return;
        }

        return await printNative(
          modeloDeImpressao.textImpressao,
          Number(qtdeColunasPDV || '0'),
          caminho ?? '',
          modelo ?? '',
          1
        );
      }
    },
    [enviarImpressaoLocal, getConfigByCod, getImpressoraPdv, getPDV, printHtml, printNative, retornarModeloImpressao]
  );

  const imprimirParcelas = useCallback(async (impressao: string) => {
    await imprimir({
      impressao,
      tipoImpressao: EnumTipoImpressao.PARCELAS,
    })
  }, [imprimir])

  const imprimirPagamento = useCallback(async (impressao: string) => {
    await imprimir({
      impressao,
      tipoImpressao: EnumTipoImpressao.PAGAMENTO,
    })
  }, [imprimir])

  const enviarCupomPorEmail = useCallback(
    async (idVenda: string, email: string): Promise<void> => {
      const res = await postEnviarCupomVenda(idVenda, email);
      if (res.erro) throw new Error(res.erro);
    },
    [postEnviarCupomVenda]
  );

  const enviarCupomVendaOffline = useCallback(
    async (idVenda: string, email: string): Promise<void> => {
      const vendaOFF = await getVenda(idVenda);
      const qtdeColunasPDV = getConfigByCod(EnumPDVConfigCod.QuantidadeColunas);
      const impressora = getImpressoraPdv();

      const qtdeColunas = impressora ? impressora.colunas : qtdeColunasPDV

      if (!vendaOFF) {
        await enviarCupomPorEmail(idVenda, email);
        return;
      }

      const vendaOffConvertida = await converterParaModeloDeImpressao(vendaOFF);

      const consumidorPadraoOff = await getConsumidor();
      if (!consumidorPadraoOff)
        throw new Error('O Consumidor padrão não foi Identificado.');

      let impressaoOFF = '';

      if (vendaOffConvertida.infMov.mod === EnumMovModelo.NFCE) {
        impressaoOFF = impressaoNFCe(
          vendaOffConvertida,
          Number(qtdeColunas ?? '58'),
          3,
          2,
          consumidorPadraoOff
        );
      } else {
        impressaoOFF = impressaoMov(
          vendaOffConvertida,
          Number(qtdeColunas ?? '58'),
          3,
          2,
          consumidorPadraoOff
        );
      }

      const imprimir = new ImpressaoSaurus('HTML');

      let cupom = imprimir.Traduz(impressaoOFF) ?? '';

      const response = await postEnviarCupomVendaEmail(
        email,
        'Nota disponível para visualização',
        cupom
      );

      if (response.erro) {
        throw new Error('Não foi possível realizar o envio do email.');
      }
    },
    [
      converterParaModeloDeImpressao,
      enviarCupomPorEmail,
      getConfigByCod,
      getConsumidor,
      getImpressoraPdv,
      getVenda,
      impressaoMov,
      impressaoNFCe,
      postEnviarCupomVendaEmail
    ]
  );

  const enviarCupomPorSMS = useCallback(
    async (idVenda: string, numero: string, mod: number = 65) => {
      let cupom = '';

      if (mod === EnumMovModelo.NFCE) {
        const ret = await getObterCupomVenda(idVenda);

        if (ret.erro) {
          throw new Error(ret.erro);
        }

        cupom = ret.resultado!.data.cupom;
      } else {
        const vendaOFF = await getVenda(idVenda);

        if (!vendaOFF) {
          await enviarCupomPorSMS(idVenda, numero, 65);
          return;
        }

        const vendaOffConvertida = await converterParaModeloDeImpressao(
          vendaOFF
        );

        const consumidorPadraoOff = await getConsumidor();
        if (!consumidorPadraoOff)
          throw new Error('O Consumidor padrão não foi Identificado.');

        let impressaoOFF = '';
        if (vendaOffConvertida.infMov.mod === EnumMovModelo.NFCE) {
          impressaoOFF = impressaoNFCe(
            vendaOffConvertida,
            Number(68),
            3,
            2,
            consumidorPadraoOff
          );
        } else {
          impressaoOFF = impressaoMov(
            vendaOffConvertida,
            Number(68),
            3,
            2,
            consumidorPadraoOff
          );
        }

        const imprimir = new ImpressaoSaurus('HTML');

        cupom = imprimir.Traduz(impressaoOFF) ?? '';
      }

      const SMS = new EnviarSMSModel();
      SMS.telefoneDestinatario = stringNumeros(numero);
      SMS.mensagem = `Sua venda está disponível para visualização. Dados empresa: ${usuario?.saudacao.slice(
        0,
        25
      )}, CPF/CNPJ: ${usuario?.empresa[0].Documento
        }. \nVisualizar cupom: @link`;
      SMS.dadosAnexo!.nomeArquivo = 'cupom';
      SMS.dadosAnexo!.anexo = cupom;
      SMS.dadosAnexo!.extensao = 'html';

      const retSMS = await postEnviarCupomVendaSMS(SMS);
      if (retSMS.erro) {
        throw retSMS.erro;
      }
    },
    [
      converterParaModeloDeImpressao,
      getConsumidor,
      getObterCupomVenda,
      getVenda,
      impressaoMov,
      impressaoNFCe,
      postEnviarCupomVendaSMS,
      usuario?.empresa,
      usuario?.saudacao
    ]
  );

  return {
    carregando,
    enviarCupomPorEmail,
    enviarCupomVendaOffline,
    imprimirCupom,
    enviarCupomPorSMS,
    imprimirTicket,
    imprimir,
    imprimirParcelas,
    imprimirPagamento,
  };
}
