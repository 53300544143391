import { useDefaultCadastroStyles } from '../components/default-cadastro-styles';
import { LocationProps, UploadCargaDetalheHeader } from './components/upload-carga-detalhes-header/upload-carga--detalhe-header';
import { Box, Button, Grid, Typography, useTheme } from '@material-ui/core';
import { useToastSaurus } from 'services/app';
import { useCallback, useEffect, useRef, useState } from 'react';
import { CargasTabs } from './components/cargas-tabs/cargas-tabs';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useConfirm } from 'material-ui-confirm';
import { UploadCargaDetalhadoModel, UploadCargaModel } from 'model/api/gestao/upload-carga/upload-carga-model';
import { CircularLoading, LoadingButton } from 'views/components';
import { useGetCargaDetalhes } from 'data/api/gestao/carga/get-carga-detalhes';
import { usePutConfirmarCarga } from 'data/api/gestao/carga/put-carga';
import { useDeleteCancelarCarga } from 'data/api/gestao/carga/delete-carga';
import { ConfirmarIcon } from 'views/components/icons/confirmar-icon';
import { CancelarIcon } from 'views/components/icons';
import { CardNaoEncontrado } from 'views/components/cards';
import { EnumUploadCarga } from 'model/enums/enum-upload-carga';

export const UploadCargaDetalhesPage = () => {
  const classes = useDefaultCadastroStyles();
  const { id } = useParams<any>();
  const { putConfirmarCarga, carregando: carregandoConfirmarPut } =
    usePutConfirmarCarga();
  const { showToast } = useToastSaurus();
  const confirm = useConfirm();
  const { deleteCancelarCarga, carregando: carregandoCancelarDelete } =
    useDeleteCancelarCarga();
  const theme = useTheme();
  const { getCargaDetalhes, carregando: carregandoGet } = useGetCargaDetalhes();
  const [preenchendoTela, setPreenchendoTela] = useState(true);
  const [cargasState, setCargasState] = useState<UploadCargaDetalhadoModel>(new UploadCargaDetalhadoModel());
  const interval = useRef<NodeJS.Timer>();
  const history = useHistory()
  const { state } = useLocation<LocationProps>()

  const carregando =
    carregandoConfirmarPut ||
    carregandoCancelarDelete ||
    carregandoGet ||
    preenchendoTela;

  const getCargasByIdWrapper = useCallback(async () => {
    const res = await getCargaDetalhes(id);
    if (res.erro) {
      throw res.erro;
    }
    const ret = res.resultado?.data as UploadCargaDetalhadoModel;

    return ret;
  }, [getCargaDetalhes, id]);

  const carregarTela = useCallback(async () => {
    try {
      setPreenchendoTela(true);
      const cargas = await getCargasByIdWrapper();
      setCargasState(cargas);
    } catch (e: any) {
      showToast('error', e.message);
    } finally {
      setPreenchendoTela(false);
    }
  }, [getCargasByIdWrapper, showToast])

  useEffect(() => {
    if (cargasState.status === EnumUploadCarga.ANALISANDO ||
      cargasState.status === EnumUploadCarga.PENDENTE ||
      cargasState.status === EnumUploadCarga.CONFIRMACAO) {
      interval.current = setInterval(async () => {
        const ret = await getCargasByIdWrapper();
        setCargasState(ret);
        if (ret.status !== EnumUploadCarga.ANALISANDO &&
          ret.status !== EnumUploadCarga.PENDENTE &&
          cargasState.status !== EnumUploadCarga.CONFIRMACAO
        ) {
          clearInterval(interval.current);
        }
      }, 20000)
    }
    return () => {
      if (interval.current)
        clearInterval(interval.current)
    }
  }, [cargasState.status, getCargasByIdWrapper])

  useEffect(() => {
    carregarTela()
  }, [carregarTela]);

  const patchUploadConfirmarCarga = useCallback(
    async (id: string) => {
      try {
        const ret = await putConfirmarCarga(id);

        if (ret.erro) {
          throw ret.erro;
        }

        const data = ret.resultado?.data as UploadCargaModel;

        setCargasState(prev => ({ ...prev, status: data.status.codigo }));

        showToast(
          'success',
          'Carga confirmada com sucesso!',
          2000
        );
      } catch (e: any) {
        showToast('error', e.message);
      }
    },
    [putConfirmarCarga, showToast],
  );

  const cancelarCarga = useCallback(
    async (id: string) => {
      try {
        const ret = await deleteCancelarCarga(id);

        if (ret.erro) {
          throw ret.erro;
        }

        showToast(
          'success',
          'Carga cancelada com sucesso!'
        );
      } catch (e: any) {
        showToast('error', e.message);
      }
    },
    [deleteCancelarCarga, showToast],
  );

  const handleVoltar = useCallback(() => {
    if (state && state.from && state.from.page) {
      history.push(`/upload-carga?page=${state.from.page}`)
      return
    }
    history.push('/upload-carga')
  }, [history, state])

  const carregandoCard = <Box mt={6}>
    <CardNaoEncontrado
      mensagem="Sua carga está pendente na nossa fila de análise."
      iconSize='normal'
      icon={<LoadingButton tipo='AZUL' size='LARGE' />}
    />
  </Box>

  const switchPage = () => {
    switch (cargasState.status) {
      case EnumUploadCarga.PENDENTE:
        return carregandoCard;
      case EnumUploadCarga.ANALISANDO:
        return carregandoCard;
      case EnumUploadCarga.REJEITADO:
        return <Box mt={6}>
          <CardNaoEncontrado
            mensagem={
              <Box display='flex' flexDirection='column' gridGap={2}>
                <Typography
                  color='textPrimary'
                  variant='h4'
                >
                  Carga Rejeitada
                </Typography>
                <Typography
                  color='textSecondary'
                  variant='body1'
                >
                  Ops! Ocorreu algum problema para processar sua carga.
                </Typography>
              </Box>
            }
            iconSize='normal'
            icon={<CancelarIcon />}
          />
        </Box>
      default:
        return <CargasTabs carga={cargasState} />
    }
  }

  return (
    <Grid className={classes.root}>
      {carregando ? <CircularLoading tipo="FULLSIZED" /> : null}
      <Grid className={classes.header}>
        <UploadCargaDetalheHeader handleVoltar={handleVoltar} status={cargasState?.status} />
      </Grid>
      <Grid className={classes.list}>
        {switchPage()}
      </Grid>
      <div className={classes.acoes}>
        {(cargasState?.status === EnumUploadCarga.AGUARDANDO) && !carregando ? (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Button
                style={{ background: theme.palette.error.main }}
                disabled={carregando}
                variant="contained"
                color="primary"
                fullWidth
                onClick={() => {
                  confirm({
                    title: `Deseja cancelar a carga?`,
                    description: `Ao confirmar o cancelamento da carga, nada será aplicado aos seus dados de cadastro. Deseja confirmar?`,
                    cancellationText: 'Voltar',
                    cancellationButtonProps: {
                      color: 'default',
                    },
                    confirmationText: 'Confirmar Cancelamento',
                  }).then(async () => {
                    await cancelarCarga(id);
                    handleVoltar();
                  });
                }}
              >
                <CancelarIcon tipo='BUTTON_PRIMARY' />
                Cancelar Carga
              </Button>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button
                disabled={carregando}
                onClick={() => {
                  confirm({
                    title: `Deseja confirmar a carga?`,
                    description: `Ao confirmar a carga, as alterações previstas serão aplicadas e refletidas nos seus dados de cadastro. Deseja Continuar?`,
                    cancellationText: 'Voltar',
                    cancellationButtonProps: {
                      color: 'default',
                    },
                    confirmationText: 'Confirmar Carga',
                  }).then(async () => {
                    await patchUploadConfirmarCarga(id);
                  });
                }}
                variant="contained"
                color="primary"
                fullWidth
              >
                <ConfirmarIcon tipo='BUTTON_PRIMARY' />
                Confirmar Carga
              </Button>
            </Grid>
          </Grid>
        ) : (
          <></>
        )}
      </div>
    </Grid>
  );
};

export default UploadCargaDetalhesPage;
