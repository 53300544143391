import { Button, Grid, Typography } from '@material-ui/core';
import { useStyles } from './sessao-sincronizar-fechamento-styles';
import { LoadingFinalizacao } from 'views/components/utils/loading-finalizacao/loading-finalizacao';
import { useThemeQueries } from 'views/theme';
import { useToastSaurus } from 'services/app';
import { useSessaoAtual } from 'services/app';
import { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { CancelarIcon, VoltarIcon } from 'views/components/icons';
import { useSessaoPDV } from 'services/app/hooks/sessao-pdv';
import { SessaoFechamento } from 'model/api/gestao/sessao/sessao-valor-model';
import { RetornarIcon } from 'views/components/icons/retornar-icon';
import { usePostFecharSessao } from 'data/api/gestao/sessao/post-fechar-sessao';
import { useGetSessaoImpressao } from 'data/api/gestao/sessao/get-impressao';
import { usePDV } from 'services/app/hooks/pdv';
import { TouchoneDBPrimary } from 'database/touchone-database';
import { AppEventEnum } from 'model/enums/enum-app-event';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { useSincronizacaoGeral } from 'services/app/hooks/sincronizar-dados';
import { EnumSincronizacaoGeralStatus } from 'model/enums/enum-sincronizacao-geral-status';
import { EnumSincronizacaoGeral } from 'model/enums/enum-sincronizacao-geral';
import { PdvRotasMock } from 'data/mocks/pdv-rotas-mock';
import { useVendaHeaderStore } from 'views/components/headers/venda-header/stores/venda-header-store';

interface LocationProps {
  itens: SessaoFechamento[];
}

const SincronizarSessaoFechamentoPage = () => {
  // STATES E REFS
  const [sessaoAtualId, setSessaoAtualId] = useState<string | undefined>(
    undefined,
  );
  const [isSinc, setIsSinc] = useState<boolean>(false);
  const [number, setNumber] = useState<number>(0);
  const [erro, setErro] = useState<boolean>(false);

  // HOOKS
  const { getEmpresaSelecionada } = useSessaoAtual();
  const { getPDV, setInicializacaoVendaRealizada } = usePDV();
  const { callEvent } = useEventTools();
  const {
    getSessao,
    verificarItensParaFechamento,
    fecharSessao,
    imprimirResumo,
    atualizarValoresFechamento,
    verificarItensParaSincronizacao,
    agruparValores,
  } = useSessaoPDV();
  const { setStatusSincronizacaoAtual } = useSincronizacaoGeral();

  // CHAMADAS API
  const { postFecharSessao } = usePostFecharSessao();
  const { getSessaoImpressao } = useGetSessaoImpressao();

  // AUX
  const classes = useStyles();
  const { isMobile } = useThemeQueries();
  const history = useHistory();
  const { showToast } = useToastSaurus();
  const { state } = useLocation<LocationProps>();
  const { itens } = state;

  const getResumo = useCallback(
    async (sessaoId: string) => {
      try {
        const pdvEmUso = getPDV();
        const configQtdColuna = pdvEmUso?.configuracoes.find((p) => p.cod === 57);
        const qtdColuna = (configQtdColuna?.vConfig ?? 40);
        const res = await getSessaoImpressao(
          getEmpresaSelecionada()!.Id,
          getPDV()!.id,
          sessaoId,
          qtdColuna
        );

        if (res.erro) {
          throw new Error(res.erro);
        }

        await imprimirResumo(res.resultado?.data?.texto ?? '', pdvEmUso?.numCaixa.toString() ?? '', false, true);
      } catch (e: any) {
        showToast('error', e.message);
      }
    },
    [
      getEmpresaSelecionada,
      getPDV,
      getSessaoImpressao,
      imprimirResumo,
      showToast,
    ],
  );

  const sincronizarValores = useCallback(
    async (caixaId: string, valores: SessaoFechamento) => {
      const respostaPost = await postFecharSessao(
        getEmpresaSelecionada()!.Id,
        caixaId,
        valores.sessaoId,
        valores.payload,
      );

      if (respostaPost.erro) {
        if (respostaPost.statusCode !== 422) {
          throw respostaPost.erro;
        }
      }

      await getResumo(valores.sessaoId);
      await fecharSessao(valores.sessaoId);
      await atualizarValoresFechamento(valores.sessaoId);
      setInicializacaoVendaRealizada(false)
    },
    [
      atualizarValoresFechamento,
      fecharSessao,
      getEmpresaSelecionada,
      getResumo,
      postFecharSessao,
      setInicializacaoVendaRealizada,
    ],
  );

  const sincronizar = useCallback(async () => {
    try {
      if (number < itens?.length) {
        await sincronizarValores(getPDV()!.id, itens[number]);
      }
      if (number >= itens?.length - 1) {
        callEvent(AppEventEnum.SessaoPDV, 1);

        setStatusSincronizacaoAtual({
          ativo: false,
          sincronizandoAtual: 0,
          status: EnumSincronizacaoGeralStatus.Finalizado,
          tipo: EnumSincronizacaoGeral.SessaoValores,
          totalAsincronizar: 0,
        });

        history.push(`/`);
        return;
      }

      setNumber((prev) => prev + 1);
    } catch (e: any) {
      setErro(true);
      showToast('error', e.message);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [number, itens]);

  useEffect(() => {
    if (isSinc) {
      (async () => {
        await sincronizar();
      })();
      return;
    }

    if (itens?.length === 0) {
      history.push({
        pathname: '/',
      });
    }

    (async () => {
      const sessao = await getSessao();
      setSessaoAtualId(sessao?.id ?? undefined);

      const valoresNaoSincronizados = await verificarItensParaFechamento();

      if (!valoresNaoSincronizados) {
        const itens = await verificarItensParaSincronizacao();
        if (!itens || itens.length === 0) {
          return history.push('/');
        }

        const agrupado = agruparValores(itens, 20);
        history.push({
          pathname: PdvRotasMock.gerenciarSessaoSincronizar,
          state: { itens: agrupado },
          search: '?redirect=fechamento',
        });
        return;
      }

      setIsSinc(true);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSinc, sincronizar]);

  const fecharESincronizarMaisTarde = useCallback(async () => {
    const sessao = await TouchoneDBPrimary.sessao.get({ id: sessaoAtualId });

    if (sessao?.aberta) {
      await TouchoneDBPrimary.sessao.update(sessao.idIndexed!, {
        aberta: false,
      });
    }

    setInicializacaoVendaRealizada(false)
    callEvent(AppEventEnum.SessaoPDV, 1);
    return history.push('/');
  }, [callEvent, history, sessaoAtualId, setInicializacaoVendaRealizada]);

  const setHeaderProps = useVendaHeaderStore(state => state.setHeaderProps);
  useEffect(() => {
    setHeaderProps({ title: 'Sincronizando Fechamentos' })
  }, [setHeaderProps])

  return (
    <>
      <Grid
        className={`${classes.container} ${classes.containerBackgroundColor}`}
      >
        <Grid className={classes.content}>
          <Grid
            className={
              isMobile ? classes.infoContainer : classes.infoContainerPC
            }
          >
            <Typography className={classes.textTitle}>
              {!erro ? 'Sincronizando' : 'Ocorreu um erro'}
            </Typography>
            {!erro ? (
              <Typography
                className={classes.infoTotal}
                style={{ textAlign: 'center' }}
              >
                {number + 1}/{itens?.length ?? 'Carregando...'}
              </Typography>
            ) : (
              <></>
            )}
            <Grid
              style={{
                display: 'flex',
                justifyContent: 'center',
                padding: 32,
              }}
            >
              {!erro ? (
                <LoadingFinalizacao />
              ) : (
                <Grid className={classes.imageError}>
                  <CancelarIcon tipo="GERAL" fill="#D22" />
                </Grid>
              )}
            </Grid>
            <Grid
              className={!isMobile ? classes.infoCardContent : classes.cardInfo}
            >
              <Grid
                container
                justifyContent="center"
                style={{ flexDirection: 'column', display: 'flex' }}
              >
                <Grid
                  item
                  style={{ display: 'flex', justifyContent: 'center' }}
                >
                  {!erro ? (
                    <Typography className={classes.infoTotal}>
                      Conjunto nº {number + 1}
                    </Typography>
                  ) : (
                    <Typography className={classes.infoNomeEQuantidade}>
                      Ocorreu um erro ao sincronizar os dados
                    </Typography>
                  )}
                </Grid>
                {erro && (
                  <Grid
                    item
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                    }}
                  >
                    <Button
                      variant="contained"
                      onClick={async () => {
                        setErro(false);
                        await sincronizar();
                      }}
                      color="primary"
                      size="large"
                      style={{ marginTop: 32 }}
                    >
                      <RetornarIcon tipo="BUTTON_PRIMARY" />
                      Tentar Novamente
                    </Button>

                    <Button
                      variant="contained"
                      onClick={async () => {
                        await fecharESincronizarMaisTarde();
                      }}
                      color="primary"
                      size="large"
                      style={{ marginTop: 32 }}
                    >
                      <VoltarIcon tipo="BUTTON_PRIMARY" />
                      Tentar Mais tarde
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default SincronizarSessaoFechamentoPage;
