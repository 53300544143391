import { FC, useCallback, useEffect, useMemo } from 'react';
import { MenuPrincipal } from './menu-principal';
import { useStyles } from './main-page-styles';
import { useVerificarDispositivo } from 'services/app/hooks/verificar-dispositivo';
import { MenuFavoritos } from './menu-favoritos/menu-favoritos';
import { useMenuStorage } from 'services/app/hooks/menu-storage';
import { isEmpty, isEqual } from 'lodash';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { AppEventEnum } from 'model/enums/enum-app-event';
import { EnumMenuPrincipalModo } from 'model/enums/enum-menu-principal-modo';
import { useLocation } from 'react-router-dom';
import { useMenuPrincipal } from 'services/app/hooks/menu-principal';
import classNames from 'classnames';
import { Tour } from 'views/components/tour/tour-wrapper';
import { useTourSaurus } from 'services/app/hooks/tour-saurus';
import { EnumTour } from 'model/enums/enum-tour';
import { GestaoStorageKeys, useGestaoStorage, useSessaoAtual, useToastSaurus } from 'services/app';
import { useGetTutorial } from 'data/api/gestao/tutorial/get-tutorial';
import { sistemaId, VariaveisAmbiente } from 'config';
import { TutorialResModel } from 'model/api/gestao/tutorial/tutorial-post-model';
import { isPlanoFarmaceutico } from 'utils/plano-utils';
import { useVerificarAbas } from 'services/app/hooks/verificar-abas';
import { EnumTipoCobranca } from 'model/enums/enum-tipo-combranca';
import { MenuPrincipalPDV } from './menu-principal-pdv/menu-principal-pdv';
import { MovRotasMock } from 'data/mocks/mov-rotas-mock';
import { Box } from 'views/design-system';
import VendaHeader from 'views/components/headers/venda-header/venda-header';
import { getDevice } from 'config/environment-variables';
import { EnumDeviceType } from 'model/enums/enum-device-type';


export const MainPage: FC = ({ children }) => {

    const { dispararVerificacao } = useVerificarDispositivo();
    useEffect(() => {
        setTimeout(() => {
            dispararVerificacao();
        }, 7500)
    }, [dispararVerificacao])

    const { plano, deslogar, usuario, meuUsuario, isPlanoAvulso, getEmpresaSelecionada } = useSessaoAtual();
    const isFarma = useMemo(() => isPlanoFarmaceutico(plano?.plano), [plano?.plano])
    const deslogarUser = useCallback(() => {
        deslogar(usuario?.usuarioId || '')
    }, [deslogar, usuario?.usuarioId])

    useVerificarAbas(deslogarUser);

    const { get } = useMenuStorage();
    const { alterarModo } = useMenuPrincipal()

    const menuAtual = get();
    const modoPDV = menuAtual.modo === EnumMenuPrincipalModo.PDV;

    const classes = useStyles();
    const location = useLocation()
    const { callTour } = useTourSaurus()
    const { getRegistro, setRegistro } = useGestaoStorage()
    const { getTutorial } = useGetTutorial()
    const { callEvent } = useEventTools()
    const { showToast } = useToastSaurus()

    const menu = useMemo(() => <MenuPrincipal />, []);
    const menuPrincipalPDV = useMemo(() => <MenuPrincipalPDV />, [])
    const menuFavoritos = useMemo(() => <MenuFavoritos renderTour={true} />, [])

    const getTutoriais = useCallback(async (tipoDeReferencia: string) => {
        try {
            const res = await getTutorial(tipoDeReferencia, sistemaId)

            if (res.erro) throw res.erro
            return res.resultado?.data as TutorialResModel[]
        } catch (e: any) {

            showToast('error', e.message)
        }
    }, [getTutorial, showToast])

    const iniciarWizard = useCallback(async () => {
        if (getDevice() === EnumDeviceType.CORDOVA_POS) return
        const contratoId = getEmpresaSelecionada()?.ContratoId ?? ""
        const tutoriaisContrato = await getTutoriais(contratoId)
        if (typeof tutoriaisContrato === 'object') {
            const wizard = tutoriaisContrato.find((x: TutorialResModel) => x.tipo === EnumTour.WIZARD)

            if (wizard && isEmpty(wizard.tutorialStatus)) {
                callEvent(AppEventEnum.DialogConfigIniciais, { aberto: true });
            }
        }
    }, [callEvent, getEmpresaSelecionada, getTutoriais])

    const carregarTutoriais = useCallback(async () => {
        try {
            let tutorialStorage = getRegistro(GestaoStorageKeys.Tutoriais, false)

            let tutoriais: TutorialResModel[] = []
            let tutoriaisAtualizados = await getTutoriais(meuUsuario?.id ?? "") as TutorialResModel[]

            if (isEmpty(tutorialStorage)) {
                tutoriais = tutoriaisAtualizados
                setRegistro(GestaoStorageKeys.Tutoriais, tutoriais, false)
                return tutoriais
            }
            else if (!isEqual(tutorialStorage, tutoriaisAtualizados)) {
                tutoriais = tutorialStorage
                setRegistro(GestaoStorageKeys.Tutoriais, tutoriais, false)
                return tutoriais
            } else {
                tutoriais = tutorialStorage
                setRegistro(GestaoStorageKeys.Tutoriais, tutoriais, false)
                return tutoriais
            }
        } catch (e: any) {
        }
    }, [getRegistro, getTutoriais, meuUsuario?.id, setRegistro])

    const podeExibirWizardPelaData = useCallback(() => {
        const dataDeAdesao = plano?.contratado.dAdesao ?? ''
        const dataPermitidaaPartirDe = "2024-06-21T00:00:00.000000"
        if (dataDeAdesao > dataPermitidaaPartirDe) {
            return true
        }
        return false
    }, [plano?.contratado.dAdesao])

    const pesquisasDeTutoriais = useCallback(async () => {
        const tipoDefaturamento = usuario?.licenca.RepresentanteTipoCobranca
        if ((!isPlanoAvulso() && tipoDefaturamento === EnumTipoCobranca.ClienteFinal && podeExibirWizardPelaData()) || !isFarma) {
            if (VariaveisAmbiente.isDev) {
                await iniciarWizard()
            }
            await carregarTutoriais()
            return
        }
        await carregarTutoriais()
        callEvent(AppEventEnum.FinalizouWizard, { finalizado: true }) //Esse CallEvent serve para finalizar o wizard para seguir com os Tours
    }, [callEvent, carregarTutoriais, iniciarWizard, isFarma, isPlanoAvulso, podeExibirWizardPelaData, usuario?.licenca.RepresentanteTipoCobranca])

    useEffect(() => {
        pesquisasDeTutoriais()
    }, [pesquisasDeTutoriais])

    useEffect(() => {
        [MovRotasMock.catalogoRota, MovRotasMock.lancamentoAvulsoRota].includes(location.pathname) && setTimeout(() => { callTour(EnumTour.VENDA) }, 500)
    }, [callTour, location.pathname])

    useEffect(() => {
        location.pathname.indexOf(MovRotasMock.baseRota) > -1
            ? alterarModo(EnumMenuPrincipalModo.PDV)
            : alterarModo(EnumMenuPrincipalModo.Retaguarda);
    }, [alterarModo, location.pathname, location.search]);

    return (
        <Box className={classes.mainContainer}>
            {modoPDV && <VendaHeader />}
            <div className={classNames(classes.containerBody,
                modoPDV ? classes.flexContainer : null,
            )}>
                {menuAtual.modo === EnumMenuPrincipalModo.Retaguarda && (
                    <>
                        {menu}
                    </>
                )}
                {menuAtual.modo === EnumMenuPrincipalModo.PDV && (
                    <>
                        {menuPrincipalPDV}
                        {menuFavoritos}
                    </>
                )}
                {children}
                <Tour />
            </div>
        </Box >
    );
};
