import classNames from 'classnames';
import { useStyles } from './carrinho-vazio-styles';
import { CarrinhoIcon } from 'views/components/icons/carrinho-icon';
import { Grid, Typography } from 'views/design-system';
import { CarrinhoVazioProps } from './carrinho-vazio-props';
// import { PublicPageHeader } from 'views/components/headers';

export const CarrinhoVazio = ({ hasProdutos }: CarrinhoVazioProps) => {
  const classesCarrinhoVazio = useStyles();

  return (
    <>
      {/* <PublicPageHeader styles={{ zIndex: 0 }} topOpacity="0.8" /> */}
      <Grid className={classNames(classesCarrinhoVazio.containerCarrinhoVazio, hasProdutos ? classesCarrinhoVazio.none : undefined)}>
        <Grid
          className={classNames(
            classesCarrinhoVazio.cardCarrinhoVazio
          )}
        >
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
          >
            <Typography
              className={classesCarrinhoVazio.title}
              color="textPrimary"
              variant="h4"
            >
              CAIXA ABERTO
            </Typography>
            <CarrinhoIcon tipo="INPUT" />
            <Typography
              className={classesCarrinhoVazio.textInfo}
              color="textPrimary"
              variant="h4"
            >
              Adicione ou Passe algum Produto.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
