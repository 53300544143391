import { MovSimplesProdutoModel } from 'model/api/gestao/movimentacao/simples/mov-simples-produto-model';
import { useCallback, useEffect, useRef, useState } from 'react';
import CardCarrinhoGrupoProdutos from 'views/components/cards/card-carrinho-grupo-produtos/card-carrinho-grupo-produtos';
import CardCarrinho from 'views/components/cards/card-carrinho/card-carrinho';
import { useStyles } from './carrinho-list-data-styles';
import classNames from 'classnames';
import { useAgruparProdutos } from 'services/app/hooks/agrupar-produtos';
import { useContratoAtual } from 'services/app/hooks/contrato-atual';
import { EnumContratoConfig } from 'model/enums/enum-contrato-config';
import { Divider, Grid, Typography } from 'views/design-system';
import { CarrinhoVazio } from '../carrinho-vazio/carrinho-vazio';
import { useThemeQueries } from 'views/theme';

export interface CarrinhoGridProps {
  list: Array<MovSimplesProdutoModel>;
  mostrarItensCancelados: boolean;
  inativarProduto(model: MovSimplesProdutoModel): Promise<void>;
  reativarProduto(model: MovSimplesProdutoModel): Promise<void>;
  alterarQtdeProduto(
    model: MovSimplesProdutoModel,
    incremento: number,
    fator: 'sub' | 'add'
  ): Promise<void>;
  removerPedidoDoCarrinho(idPedido: string): Promise<void>;
  setProductEdit: (produtoId: string) => void;
}
export interface CarrinhoListPedido {
  pedidoId: string;
  comandaId: string;
  mesaId: string;
  codigoPedido: string;
  identificador: string;
  produtos: MovSimplesProdutoModel[];
}
export const CarrinhoListData = ({
  list,
  mostrarItensCancelados,
  inativarProduto,
  reativarProduto,
  alterarQtdeProduto,
  removerPedidoDoCarrinho,
  setProductEdit
}: CarrinhoGridProps) => {
  // STATES E REFS
  const [visibleItems, setVisibleItems] = useState(15);
  const [divHeightTotal, setDivHeightTotal] = useState<number>(0);
  const divRef = useRef<HTMLDivElement>(null);

  // HOOKS
  const { agruparProdutosPorAdicionais, agruparProdutosPorPedido } =
    useAgruparProdutos(list ?? []);
  const { getConfigByCod } = useContratoAtual();
  const prodTaxaServicoId = getConfigByCod(EnumContratoConfig.ProdutoServico);

  // AUX
  const classes = useStyles();
  const { isMobile } = useThemeQueries()

  const pedidos = agruparProdutosPorPedido();
  const produtosComAdicionais = agruparProdutosPorAdicionais();

  const produtosCarrinho = list.filter((produto) => {
    if (!(prodTaxaServicoId === produto.produtoId)) {
      if (!mostrarItensCancelados) {
        return produto.ativo;
      } else {
        return produto;
      }
    }
    return false;
  });

  const handleScroll = useCallback(() => {
    if (
      divRef.current &&
      divRef.current.scrollTop + divRef.current.clientHeight + 10 >=
      divRef.current.scrollHeight
    ) {
      setVisibleItems((prevVisibleItems) => {
        if (prevVisibleItems < produtosCarrinho.length) {
          return prevVisibleItems + 10;
        }
        return prevVisibleItems;
      });
    }
  }, [produtosCarrinho.length]);

  useEffect(() => {
    const div = divRef.current;

    const idTimeout = setTimeout(() => {
      div?.addEventListener('scroll', handleScroll);
    }, 1350);

    return () => {
      if (div) {
        div.removeEventListener('scroll', handleScroll);
      }
      clearTimeout(idTimeout);
    };
  }, [handleScroll]);

  useEffect(() => {
    // Verifica constantemente se o cliente está no final e precisa de mais itens
    const div = divRef.current;

    if (
      div &&
      div.scrollTop + div.clientHeight + 10 >= div.scrollHeight &&
      visibleItems < produtosCarrinho.length
    ) {
      setVisibleItems((prevVisibleItems) => prevVisibleItems + 10);
    }
  }, [produtosCarrinho.length, visibleItems]);

  useEffect(() => {
    const updateDivHeight = () => {
      const carrinhoTotais = document.getElementById("carrinhoTotais");

      if (carrinhoTotais) {
        const height = carrinhoTotais.offsetHeight;
        setDivHeightTotal(height);
      }
    };

    // Calcula a altura ao carregar o componente
    updateDivHeight();

    // Adiciona um listener para redimensionamento da janela
    window.addEventListener("resize", updateDivHeight);

    // Limpa o listener ao desmontar o componente
    return () => {
      window.removeEventListener("resize", updateDivHeight);
    };
  }, [produtosCarrinho]);

  const height = window.innerHeight
  return (
    <>
      <CarrinhoVazio hasProdutos={produtosCarrinho.length > 0} />
      <Grid
        item
        xs={12}
        className={classNames(
          classes.listContainer,
          produtosCarrinho.length <= 0 ? classes.none : undefined
        )}
        style={{
          height: `calc(${height}px - ${divHeightTotal === 0 ? 270 : divHeightTotal + 72 + (isMobile ? 68 : 0)}px)`
        }}
        ref={divRef}
      >

        {/* Listagem de produtos por pedidos */}
        {pedidos.length > 0 ? (
          pedidos.map((item) => {
            return (
              <CardCarrinhoGrupoProdutos
                inativarProduto={inativarProduto}
                reativarProduto={reativarProduto}
                model={item}
                key={item.pedidoId}
                mostrarItensCancelados={mostrarItensCancelados}
                removerPedidoDoCarrinho={removerPedidoDoCarrinho}
              />
            );
          })
        ) : (
          <></>
        )}

        {/* Listagem de produtos de venda sem pedido atrelado */}
        {produtosComAdicionais.length > 0 ? (
          produtosComAdicionais
            .sort((a, b) => b.nSeq - a.nSeq)
            .slice(0, visibleItems)
            .map((item, index) => {
              return (
                <div
                  key={item.id}
                  style={{
                    display: !mostrarItensCancelados && !item.ativo ? 'none' : 'block'
                  }}
                >
                  {(index === 0 ||
                    produtosComAdicionais[index - 1].vendedorId !==
                    item.vendedorId) && (
                      <div className={classes.vendedorContent}>
                        <Typography className={classes.vendedorText}>
                          Vendedor: <b>{item.vendedorNome}</b>
                        </Typography>
                        <Divider className={classes.vendedorDivider} />
                      </div>
                    )}
                  <CardCarrinho
                    inativarProduto={inativarProduto}
                    reativarProduto={reativarProduto}
                    alterarQtdeProduto={alterarQtdeProduto}
                    key={item.id}
                    model={item}
                    setProductEdit={setProductEdit}
                  />
                </div>
              );
            })
        ) : (
          <></>
        )}
      </Grid>
    </>
  );
};